.thank-you-message {
    padding: 40px;
    margin: 20px auto;
    max-width: 800px;
    background: #f0f0f0;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    text-align: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

.thank-you-message h2 {
    color: #6d0101;
    margin-bottom: 20px;
}

.thank-you-message p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 16px;
}

.thank-you-message strong {
    display: block;
    margin-top: 20px;
    font-size: 18px;
    color: #6d0101;
}
