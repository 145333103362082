/* Timeline.css */
.timeline-container {
    position: relative;
    margin: 50px 0;
    padding: 0 20px;
    width: 100%;
}

.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
}

.timeline::after {
    content: "";
    position: absolute;
    width: 6px;
    background-color: #6d0101;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

.timeline-event {
    padding: 20px 40px;
    position: relative;
    width: 50%;
    background-color: inherit;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px; /* Added margin bottom for spacing */
}

.timeline-event::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: white;
    border: 4px solid #6d0101;
    top: 20px; /* Adjusted position for better alignment */
    border-radius: 50%;
    z-index: 1;
}

.timeline-event.left::after {
    right: -12.5px; /* Adjusted position to center the circle for left */
}

.timeline-event.right::after {
    left: -12.5px; /* Adjusted position to center the circle for right */
}

.timeline-event.left {
    left: 0;
}

.timeline-event.right {
    left: 50%;
}

.timeline-event.right::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 20px;
    left: -20px; /* Adjusted position for the arrow */
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent white;
}

.timeline-event.left::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 20px;
    right: -20px; /* Adjusted position for the arrow */
    border-width: 10px;
    border-style: solid;
    border-color: transparent white transparent transparent;
}

.timeline-event-content {
    padding: 20px;
    background-color: white;
    position: relative;
    border-radius: 6px;
}

.timeline-event-content h3 {
    margin-top: 0;
    color: #6d0101;
}
