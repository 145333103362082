.btn-special {
    background-color: #f8f9fa;
    color: #495057;
    border: none !important;
    letter-spacing: 1px;
    border-radius: 0.5rem;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-transform: uppercase !important;
}
.btn-special:hover {
    background-color: #e9ecef;
    color: #8f1b1b;
}
.name {
    margin-bottom: 0 !important;
}
