.resume-name {
    margin-bottom: 0 !important;
    color: #7e2028 !important;
}

.paragraph {
    margin-bottom: 20px !important;
}
@media (max-width: 600px) {
    .modal-content {
        padding: 0px; /* Daha az padding */
        font-size: 12px; /* Daha küçük font boyutu */
        max-width: 100%; /* Tam genişlik */
        max-height: 100%; /* Tam yükseklik */
    }
    .resume-image {
        display: none;
    }
    .mt-4 {
        margin-top: 0 !important;
    }
    .content {
        padding: 0px !important;
    }
    p {
        padding-left: 6%;
    }
}
