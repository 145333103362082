/* src/components/CustomModal/CustomModal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100000000;
}

.modal-content {
    background: white;
    padding: 5%;
    border-radius: 8px;
    max-width: 100%;
    width: 100%;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 11%;
    right: 6%;
    background: transparent;
    border: none;
    font-size: 36px;
    cursor: pointer;
    z-index: 1001;
}

@media (max-width: 600px) {
    .modal-content {
        padding: 10px; /* Daha az padding */
        font-size: 12px; /* Daha küçük font boyutu */
        max-width: 100%; /* Tam genişlik */
        max-height: 100%; /* Tam yükseklik */
    }
}
