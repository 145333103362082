/* WhatsAppButton.css */
.whatsapp-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #25d366;
    color: white;
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    transition: background-color 0.3s;
}

.whatsapp-button:hover {
    background-color: #128c7e;
}
